.contact {
    width: 100%;
    padding: 30px 20px;

    &__title {
        color: #fff;
        font: normal normal normal 24px/29px Montserrat;
        margin-bottom: 16px;
    }

    label {
        font-size: 12px;
        color: #fff;
    }

    input, textarea {
        padding: 0.5rem 0.75rem;
        color: #fff;

        &:focus {
            color: #fff;
        }
    }

    button {
        padding: 10px 60px;
    }
}