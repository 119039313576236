.logos {
    width: 100%;

    &__contact {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        width: 100%;
        text-align: center;
        color: #fff;
        @media screen and (max-width: 767px){
            flex-wrap: wrap;
            justify-content: center;
        }

        & > div {
            @media screen and (max-width: 767px){
                margin-right: 0 !important;
            }
        }

        label {
            font-weight: bold;
            margin-right: 10px;
        }

        a {
            color: #fff;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: .8;
        z-index: 2;
    }

    &__symbiosis {
        padding: 50px 0;
        text-align: center;
        color: #fff;
        background-color: $primary;
        z-index: 3;

        &__title {
            
        }

        &__subtitle {
            display: block;
            text-align: center;
        }
    }

    &__comming-soon {
        border-left: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 30px;
    }

    &__item {
        position: relative;
        padding-bottom: 30% !important;
        display: flex;

        @media screen and (max-width: 767px) {
            padding-bottom: 40% !important;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: none;

            @media screen and (max-width: 1024px){
                object-fit: contain;
            }
        }
    }
}